<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1>
            Анкета
          </h1>
          <div class="list passport">
            <div v-for="(participant, participantIndex) in participants" :key="'participant' + participantIndex"
                 class="list-item-wrap">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="list-item">
                      <a class="list-item-name">
                        <button class="toggleSwitch">
                          <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                          </svg>
                          <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                          </svg>
                        </button>
                        <span>{{ participant.title }}</span>
                      </a>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    Ссылка для прохождения опроса:
                    <a :href="participant.anketlink" target="_blank" style="text-decoration: none">{{ participant.anketlink }}</a>
                    <v-btn outlined @click="copyLink(participant.anketlink)" title="Скопировать в буфер обмена" style="margin-left: 20px">
                      <v-icon size="16px">mdi-content-copy</v-icon>
                    </v-btn>
                    <table>
                      <thead>
                      <tr>
                        <th></th>
                        <th>Данные на
                          {{checkQuarter() ? 'текущий' : (participant.quarter ? participant.quarter.value : "")}}
                          квартал
                          {{checkQuarter() ? '' : (participant.quarter ? participant.quarter.year + ' года' : "")}}
                        </th>
                        <th>
                          <svg
                              @click="previousQuarter(participant)"
                              style="cursor: pointer"
                              fill="none"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                                d="M15.41 16.58L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.58Z"
                                fill="#0033A0"
                            />
                          </svg>
                          <svg
                              v-if="!checkQuarter()"
                              @click="nextQuarter(participant)"
                              style="cursor: pointer"
                              fill="none"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                                d="M8.58997 16.58L13.17 12L8.58997 7.41L9.99997 6L16 12L9.99997 18L8.58997 16.58Z"
                                fill="#0033A0"
                            />
                          </svg>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td style="width: 300px;">
                          Количество {{ participant.title === 'Соискатели' ? 'соискателей' : 'работодателей' }}, прошедших опрос
                        </td>
                        <td>
                          {{ participant.anketsCount }}
                        </td>
                        <td>
                          <v-btn
                              class="btn btn-outline"
                              text
                              @click="$router.push('/filtredAnkets/' + participant.anketId + '/' +
                              + userDepartment.id + '/' + participant.quarter.value + '/' + participant.quarter.year)">
                            Анкеты
                          </v-btn>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import api from "@/modules/api";
export default {
  name: "ClientCentricityAnket",
  props: ['userRegion', 'userDepartment'],
  watch : {
    userDepartment: async function (newVal) {
      this.participants = [
        {
          title: 'Соискатели',
          // anketId: 15,
          anketId: 167679,
          // anketlink: 'https://ankets.testczn.nekadid.ru/' + 15 + '?id=' + this.userDepartment.id,
          anketlink: 'https://new.profczn.ru/' + 167679 + '?id=' + this.userDepartment.id,
        },
        {
          title: 'Работодатели',
          // anketId: 2264,
          anketId: 167682,
          // anketlink: 'https://ankets.testczn.nekadid.ru/' + 2264 + '?id=' + this.userDepartment.id,
          anketlink: 'https://new.profczn.ru/' + 167682 + '?id=' + this.userDepartment.id,
        }
      ];

      for (let participant of this.participants) {
        participant.quarter = this.currentQuarter;
        participant.anketsCount =
            (await this.loadAnketsByCzn(
                participant.anketId,
                newVal.id,
                participant.quarter.value,
                participant.quarter.year))
                .length;
      }
      this.$forceUpdate();
    }
  },

  data() {
    return {
      apiLoaded: false,
      errorText: null,
      participants: [
        {
          title: 'Соискатели',
          // anketId: 15,
          anketId: 167679,
          // anketlink: 'https://ankets.testczn.nekadid.ru/' + 15 + '?id=' + this.userDepartment.id,
          anketlink: 'https://new.profczn.ru/' + 167679 + '?id=' + this.userDepartment.id,
        },
        {
          title: 'Работодатели',
          // anketId: 2264,
          anketId: 167682,
          // anketlink: 'https://ankets.testczn.nekadid.ru/' + 2264 + '?id=' + this.userDepartment.id,
          anketlink: 'https://new.profczn.ru/' + 167682 + '?id=' + this.userDepartment.id,
        }
      ],
      currentQuarter: {value: null, year: null},
    }
  },

  methods: {
    // async initialize() {
    //   console.log('initialize')
    // },

    async previousQuarter(participant) {
      if (participant.quarter.value === 'IV') {
        participant.quarter.value = 'III';
      } else if (participant.quarter.value === 'III') {
        participant.quarter.value = 'II';
      } else if (participant.quarter.value === 'II') {
        participant.quarter.value = 'I';
      } else if (participant.quarter.value === 'I') {
        participant.quarter.value = 'IV';
        participant.quarter.year = participant.quarter.year - 1;
      }
      participant.anketsCount =
          (await this.loadAnketsByCzn(
              participant.anketId,
              this.userDepartment.id,
              participant.quarter.value,
              participant.quarter.year))
              .length;
      this.$forceUpdate();
    },

    async nextQuarter(participant) {
      if (participant.quarter.value === 'I') {
        participant.quarter.value = 'II';
      } else if (participant.quarter.value === 'II') {
        participant.quarter.value = 'III';
      } else if (participant.quarter.value === 'III') {
        participant.quarter.value = 'IV';
      } else if (participant.quarter.value === 'IV') {
        participant.quarter.value = 'I';
        participant.quarter.year = participant.quarter.year + 1;
      }
      participant.anketsCount =
          (await this.loadAnketsByCzn(
              participant.anketId,
              this.userDepartment.id,
              participant.quarter.value,
              participant.quarter.year))
              .length;
      this.$forceUpdate();
    },

    copyLink(link) {
      navigator.clipboard.writeText(link);
    },

    async loadAnketsByCzn (configId, department, quarter, year) {
      let req = await api.get("/ankets/getAnketFormDataByCzn", {configId: configId, department: department,
        quarter: quarter, year: year})
      if (req.ok) {
        return req.payload
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: loadAnketConfigs " + req.error;
      }
    },

    getCurrentQuarter() {
      let currentDate = new Date();

      if (currentDate >= new Date(currentDate.getFullYear() + '-1-21 00:00:00')
          && currentDate <= new Date(currentDate.getFullYear() + '-4-15 23:59:59')) {
        return {value: 'I', year: currentDate.getFullYear()};
      } else if (currentDate >= new Date(currentDate.getFullYear() + '-4-16 00:00:00')
          && currentDate <= new Date(currentDate.getFullYear() + '-7-15 23:59:59')) {
        return {value: 'II', year: currentDate.getFullYear()};
      } else if (currentDate >= new Date(currentDate.getFullYear() + '-7-16 00:00:00')
          && currentDate <= new Date(currentDate.getFullYear() + '-10-15 23:59:59')) {
        return {value: 'III', year: currentDate.getFullYear()};
      } else {
        if (currentDate >= new Date(currentDate.getFullYear() + '-1-1 00:00:00')
            && currentDate <= new Date(currentDate.getFullYear() + '-1-20 23:59:59')) {
          return {value: 'IV', year: currentDate.getFullYear() - 1};
        }
        return {value: 'IV', year: currentDate.getFullYear()};
      }
    },

    checkQuarter() {
      return JSON.stringify(this.currentQuarter) === JSON.stringify(this.getCurrentQuarter());
    }
  },
  async beforeMount() {
    this.currentQuarter = this.getCurrentQuarter();

    for (let participant of this.participants) {
      participant.quarter = this.currentQuarter;
      participant.anketsCount =
          (await this.loadAnketsByCzn(
              participant.anketId,
              this.userDepartment.id,
              participant.quarter.value,
              participant.quarter.year))
              .length;
    }

    this.apiLoaded = true;
  }
}
</script>

<style scoped lang="scss">
h1 {
  display: flex;
  align-items: center;
  .badge {
    width: 152px;
    height: 60px;
    background: #E8E8E9;
    border: 1px solid #E8E8E9;
    border-radius: 4px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 70px;
    color: $cblack;
  }
  .v-progress-circular {
    margin-left: 20px;
  }
  .v-btn {
    margin-left: auto;
  }
}

.manual-link {
  margin: 20px 0;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 20px;

    .v-icon {
      color: #0033a0;
      margin-right: 10px;
    }
  }
}

.list {
  table {
    margin-bottom: 25px;
    th {
      border-bottom: 1px solid #E8E8E9;
    }
    td:nth-child(2),
    th:nth-child(2) {
      text-align: right;
    }
    td > div {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      svg {
        margin-right: 8px;
        flex-shrink: 0;
        margin-top: 5px;
      }
    }
    tr:last-child td {
      border-bottom: 1px solid #E8E8E9;
    }
  }
}
.push-right {
  float: right;
  margin-top: 15px;
}
</style>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style>
.v-progress-circular__info {
  font-size: 13px !important;
  text-align: center;
  width: 100%;
}
</style>